@import '../../styles/index';

.base {
  background-color: $neutral-0;
  bottom: 0;
  box-shadow:
    0 0 2px 0 rgba(0, 0, 0, 0.1),
    0 0 27px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  padding: 2.5rem 0.5rem 0.5rem;
  position: absolute;
  width: 100%;
  z-index: 3;

  h3 {
    font-size: $font-size-24;
    line-height: 1;
    margin: 0 0 1.5rem;
  }

  table {
    display: block;
    max-height: 100px;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }

  @include media-from(sm) {
    border-radius: $border-radius-huge;
    bottom: 2rem;
    left: 2rem;
    max-width: 483px;
    padding: 1.5rem;
    width: 100%;

    table {
      max-height: none;
    }
  }
}

.content {
  flex: 1;
  overflow-y: auto;
  position: relative;

  &.overlay {
    &:before {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 70%,
        $neutral-0 100%
      );
      content: '';
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
    }

    @include media-from(sm) {
      &:before {
        content: none;
      }
    }
  }
}

.expanded {
  max-height: 80%;

  &::before {
    animation: fadeIn 0.25s;
    background-color: rgba(0, 0, 0, 0.65);
    content: '';
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    width: 100%;
  }

  .content {
    &::before {
      content: none;
    }
  }

  table {
    max-height: 1000px;
  }

  @include media-from(sm) {
    &::before {
      content: none;
    }
  }
}

.expand-toggle {
  align-items: center;
  background-color: $neutral-0;
  border-radius: 50%;
  box-shadow: $shadow-standard;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: -25px;
  transform: translateX(-50%);
  width: 48px;

  & > i {
    color: $surrogate-9;
  }

  @include media-from(sm) {
    display: none;
  }

  &.hidden {
    display: none;
  }
}

.close {
  color: $surrogate-9;
  cursor: pointer;
  display: block;
  position: absolute;
  right: 0.5rem;
  top: 0.925rem;
  z-index: 10;

  @include media-from(sm) {
    right: 1.5rem;
    top: 1.5rem;
  }
}

.image {
  border-radius: 0.75rem;
  margin-bottom: 1.5rem;
}

.type {
  --label-color: #{$surrogate-9};
  align-items: center;
  display: flex;
  font-weight: $font-weight-medium;
  gap: 0.75rem;
  margin-bottom: 0.75rem;

  .badge,
  .label {
    align-items: center;
    color: var(--label-color);
    display: flex;
    font-size: $font-size-16;
    gap: 0.5rem;

    &.owned {
      --label-color: #{$surrogate-9};
    }

    &.managed {
      --label-color: #{$blue-9};
    }

    &.photovoltaic {
      --label-color: #{$yellow-9};
    }

    &.office {
      --label-color: #{$turquoise-9};
    }

    &.powerStorage {
      --label-color: #{$violet-9};
    }

    & > i {
      color: currentColor;
    }
  }

  .badge {
    background-color: $surrogate-2;
    border-radius: $border-radius-default;
    color: $surrogate-9;
    padding: 0.25rem 0.5rem;
  }
}

.table {
  cursor: pointer;
  width: 100%;

  tbody {
    display: table;
    width: 100%;
  }

  th {
    font-weight: $font-weight-medium;
    width: 45%;
  }

  td {
    color: $neutral-12;
    font-weight: $font-weight-light;
  }

  th,
  td {
    font-size: 15px;
    text-align: left;
    vertical-align: top;
  }

  @include media-from(sm) {
    cursor: auto;
  }
}

.fadeIn {
  animation: slideIn 0.25s;

  @include media-from(sm) {
    animation: fadeIn 0.25s;
  }
}

.fadeOut {
  animation: slideOut 0.25s;

  @include media-from(sm) {
    animation: fadeOut 0.25s;
  }
}

.hint {
  margin: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: none;
  }
}

@keyframes slideOut {
  from {
    transform: none;
  }
  to {
    transform: translateY(100%);
  }
}
